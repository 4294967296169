import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../../components/header/header'
import WithHeaderLayour from '../../layouts/with-header'
import Footer from '../../components/footer/footer'
import Hero from '../../components/hero/hero'
import Quotation from '../../components/quotation/quotation'
import PageHeader from '../../components/page-header/page-header'
import SeloAbracam from '../../components/selo-abracam/selo-abracam'

const IndexPage = ({ data, location }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Header currentPath={location?.pathname} />
      <PageHeader
        headline={`Cartões`}
        description={`Uso simples e ilimitado: o multimoedas Visa Travel Money™ lhe dá toda a conveniência que sua viagem possa precisar. Ele opera com a bandeira Visa e oferece segurança e praticidade em todos os momentos.`}
      />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>Rapidez e eficiência em seus pedidos</h2>
        <div>
          Sempre pensando nas particularidades de cada cliente, disponibilizamos
          o serviço de delivery da moeda estrangeira em seu endereço de
          preferência, pois sabemos que um atendimento personalizado pode fazer
          toda a diferença para sua vida.
          <ul>
            <li>Equipe especializada</li>
            <li>Comodidade no pagamento</li>
            <li>Segurança e agilidade na entrega</li>
            <li>Cobertura de seguro durante o trajeto da entrega</li>
          </ul>
        </div>
        <h2>Vantagens</h2>
        <div>
          <ul>
            <li>
              Esqueça o risco cambial: saiba exatamente quanto você vai gastar
              em sua viagem
            </li>
            <li>Facilidade irrestrita com até seis moedas no mesmo cartão</li>
            <li>
              Efetue saques no exterior e recarregue quantas vezes for
              necessário
            </li>
          </ul>
        </div>
      </div>
      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query CartoesQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
